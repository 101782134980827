@import "./styles/config";

body {
  background-color: $color-taupe;
  color: $color-olivine;
  @include font-mr-eaves-mod-ot-reg;
}

.Boundary {
  margin: 0 auto;
  width: $App-boundary-width;

  @media screen and (max-width: $Breakpoint-tablet) {
    width: 100%;
  }
}

.App {
  background-color: $color-taupe;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: $Breakpoint-tablet) {
    width: 100%;
    height: auto;
  }
}

.North {
  background-color: $color-olivine;
  display: inline-block;
}

.Main {
  display: inline-block;
  flex: 1;
}

.South {
  background-color: $color-olivine;
  display: inline-block;
}

/* Global Forms */
.Form {
  &__block {
    margin: 0 0 24px 0;
  }
}
