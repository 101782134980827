@import "../../styles/config";

.Input {
  @include font-mr-eaves-mod-ot-reg;
  @include toRem(font-size, 18);
  @include toRem(line-height, 18);
  margin: 0;
  padding: 8px 16px;
  border: 0;
  background-color: $color-white-almost-again;
  border-bottom: 1px solid $color-sage;
  width: 100%;
  color: $color-olivine;

  &:focus {
    border-bottom: 1px solid $color-brick;
    outline: 0;
    box-shadow: 0px 4px 24px rgba(199, 86, 63, 0.16);
  }

  &:disabled {
    background-color: $color-taupe-light;
    color: $color-sage-light;
    cursor: not-allowed;
  }
}
