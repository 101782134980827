@mixin defaultBody {
  @include toRem(font-size, 18);
  @include toRem(line-height, 24);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include font-mrs-eaves-xl-serif-nar-ot-bold;
  margin: 0 0 16px 0;
  padding: 0;
  display: block;
  color: $color-olivine-dark;

  em {
    @include font-mrs-eaves-xl-serif-nar-ot-bold-italic;
  }
}

h1 {
  @include toRem(font-size, 32);
  @include toRem(line-height, 38);
}

h2 {
  @include toRem(font-size, 24);
  @include toRem(line-height, 30);
}

h3 {
  @include toRem(font-size, 20);
  @include toRem(line-height, 26);
}

h4 {
  @include defaultBody;
}

h5 {
  @include toRem(font-size, 16);
  @include toRem(line-height, 22);
}

h6 {
  @include toRem(font-size, 14);
  @include toRem(line-height, 20);
  text-transform: uppercase;
}

p {
  @include font-mr-eaves-mod-ot-reg;
  @include defaultBody;
  margin: 0 0 16px 0;
  padding: 0;
  display: block;
  color: $color-olivine-dark;

  em {
    @include font-mr-eaves-mod-ot-reg-italic;
  }
  strong,
  b {
    @include font-mr-eaves-mod-ot-bold;
  }
}

ul,
ol {
  margin: 0 0 32px 0;

  li {
    @include font-mr-eaves-mod-ot-reg;
    @include defaultBody;
    margin: 0 0 16px 0;
    color: $color-olivine-dark;

    em {
      @include font-mr-eaves-mod-ot-reg-italic;
    }
    strong,
    b {
      @include font-mr-eaves-mod-ot-bold;
    }
  }
}

ul {
  list-style: disc;
  padding: 0 0 0 18px;
}

ol {
  list-style: decimal;
  padding: 0 0 0 24px;
}
