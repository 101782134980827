@import "../../styles/config";

.PreSelectedGift {
  background-color: $color-olivine-dark;
  padding: 24px;

  &__gift {
    margin: 0 0 24px 0;
  }

  &__address,
  &__description {
    @include font-mr-eaves-mod-ot-reg;
    @include defaultBody;
    margin: 0 0 24px 0;
    color: $color-taupe;
  }

  &__description {
    &:last-child {
      margin: 0 0 32px 0;
    }
  }

  &__actions {
    padding: 0 44px;

    > .Button {
      width: 100%;
    }
  }
}
