@import "../../styles/config";

.Gift {
  max-width: $Gift-width;

  &__image {
    display: block;
    border-top-left-radius: 0;
    border-top-right-radius: $Gift-radius;
    border-bottom-left-radius: $Gift-radius;
    border-bottom-right-radius: 0;
    overflow: hidden;
    max-width: $Gift-width;
    box-shadow: 0px 2px 12px rgba(51, 59, 51, 0.24);
    margin: 0 0 8px 0;

    > img {
      display: block;
      max-width: 100%;
    }
  }

  &__title {
    @include font-mrs-eaves-xl-serif-nar-ot-bold;
    @include toRem(font-size, 24);
    @include toRem(line-height, 30);
    color: $color-olivine-dark;
    margin: 0 0 4px 0;
  }

  &__subtitle {
    @include font-mr-eaves-mod-ot-reg-italic;
    @include toRem(font-size, 18);
    @include toRem(line-height, 24);
    color: $color-olivine-dark-blend;
    margin: 0;
  }

  &._inverse {
    .Gift__title {
      color: $color-taupe;
    }

    .Gift__subtitle {
      color: $color-taupe-blend;
    }
  }
}
