@import "../../styles/config";

.ChosenGift {
  padding: 48px 16px 48px 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;
  width: 375px;

  &__wrapper {
    border-radius: 24px;
    padding: 16px 4px;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(72, 94, 72, 0.16);
  }

  &__business {
    @include font-mrs-eaves-xl-serif-nar-ot-bold-italic;
    @include toRem(font-size, 24);
    @include toRem(line-height, 32);
    margin: 0;
    text-align: center;
    color: $color-olivine;
  }

  &__location {
    @include font-mrs-eaves-xl-serif-nar-ot-reg-italic;
    @include toRem(font-size, 18);
    @include toRem(line-height, 18);
    text-align: center;
    margin: 0 0 16px 0;
    color: $color-olivine-dark-blend;
  }

  &__image {
    overflow: hidden;
    margin: 0 0 16px 0;

    > img {
      display: block;
      max-width: 100%;
    }
  }

  &__amount {
    @include font-mrs-eaves-xl-serif-nar-ot-reg-italic;
    @include toRem(font-size, 18);
    @include toRem(line-height, 18);
    position: relative;
    text-align: center;

    &_value {
      @include font-mrs-eaves-xl-serif-nar-ot-bold-italic;

      &_currency {
      }
    }
    &_type {
      color: $color-olivine-dark;
    }
  }
}
