@import "../../styles/config";

.HowItWorks {
  background-color: $color-sage-light;
  overflow: hidden;
  padding: 32px 24px;

  &__title {
    margin: 0 0 24px 0;
    padding: 0 0 8px 0;
    border-bottom: 1px solid $color-sage;
    text-align: center;

    @media screen and (max-width: $Breakpoint-tablet) {
      text-align: left;
    }
  }

  &__intro {
    @include toRem(font-size, 20);
    @include toRem(line-height, 26);
    margin: 0 0 48px 0;
    text-align: center;

    @media screen and (max-width: $Breakpoint-tablet) {
      @include defaultBody;
      margin: 0 0 24px 0;
      text-align: left;
    }
  }

  &__blocks {
    display: flex;

    @media screen and (max-width: $Breakpoint-tablet) {
      display: block;
    }
  }

  &__block {
    display: inline-block;
    width: calc(100% / 3);
    margin: 0 0 32px 0;
    padding: 0 0 0 16px;

    &_illustration {
      margin: 0 0 24px 0;
      min-height: 80px;

      > img {
        display: block;
        margin: 0 auto;
        opacity: 0.75;
      }
    }

    h2 {
      margin: 0 0 16px 0;
      text-align: center;
    }

    p {
      margin: 0;
      text-align: center;
    }

    &:first-child {
      padding-left: 0;
    }

    @media screen and (max-width: $Breakpoint-tablet) {
      display: block;
      width: 100%;
      padding: 0;

      &_illustration {
        min-height: auto;

        > img {
          margin: 0;
        }
      }

      h2 {
        text-align: left;
      }

      p {
        text-align: left;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
