/* Mr Eaves Mod OT Thin
font-family: "mr-eaves-modern", sans-serif;
font-weight: 100;
font-style: normal; */

/* Mr Eaves Mod OT Thin Italic
font-family: "mr-eaves-modern", sans-serif;
font-weight: 100;
font-style: italic; */

/* Mr Eaves Mod OT Light
font-family: "mr-eaves-modern", sans-serif;
font-weight: 200;
font-style: normal; */

/* Mr Eaves Mod OT Light Italic
font-family: "mr-eaves-modern", sans-serif;
font-weight: 200;
font-style: italic; */

/* Mr Eaves Mod OT Book
font-family: "mr-eaves-modern", sans-serif;
font-weight: 300;
font-style: normal; */

/* Mr Eaves Mod OT Book Italic
font-family: "mr-eaves-modern", sans-serif;
font-weight: 300;
font-style: italic; */

/* Mr Eaves Mod OT Reg */
@mixin font-mr-eaves-mod-ot-reg {
  font-family: "mr-eaves-modern", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Mr Eaves Mod OT Reg Italic */
@mixin font-mr-eaves-mod-ot-reg-italic {
  font-family: "mr-eaves-modern", sans-serif;
  font-weight: 400;
  font-style: italic;
}

/* Mr Eaves Mod OT Bold */
@mixin font-mr-eaves-mod-ot-bold {
  font-family: "mr-eaves-modern", sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* Mr Eaves Mod OT Bold Italic
font-family: "mr-eaves-modern", sans-serif;
font-weight: 700;
font-style: italic; */

/* Mr Eaves Mod OT Heavy */
@mixin font-mr-eaves-mod-ot-heavy {
  font-family: "mr-eaves-modern", sans-serif;
  font-weight: 800;
  font-style: normal;
}

/* Mr Eaves Mod OT Heavy Italic */
@mixin font-mr-eaves-mod-ot-heavy-italic {
  font-family: "mr-eaves-modern", sans-serif;
  font-weight: 800;
  font-style: italic;
}

/* Mr Eaves San OT Thin
font-family: "mr-eaves-sans", sans-serif;
font-weight: 100;
font-style: normal; */

/* Mr Eaves San OT Thin Italic
font-family: "mr-eaves-sans", sans-serif;
font-weight: 100;
font-style: italic; */

/* Mr Eaves San OT Light
font-family: "mr-eaves-sans", sans-serif;
font-weight: 200;
font-style: normal; */

/* Mr Eaves San OT Light Italic
font-family: "mr-eaves-sans", sans-serif;
font-weight: 200;
font-style: italic; */

/* Mr Eaves San OT Book
font-family: "mr-eaves-sans", sans-serif;
font-weight: 300;
font-style: normal; */

/* Mr Eaves San OT Book Italic
font-family: "mr-eaves-sans", sans-serif;
font-weight: 300;
font-style: italic; */

/* Mr Eaves San OT Reg
font-family: "mr-eaves-sans", sans-serif;
font-weight: 400;
font-style: normal; */

/* Mr Eaves San OT Reg Italic
font-family: "mr-eaves-sans", sans-serif;
font-weight: 400;
font-style: italic; */

/* Mr Eaves San OT Bold
font-family: "mr-eaves-sans", sans-serif;
font-weight: 700;
font-style: normal; */

/* Mr Eaves San OT Bold Italic
font-family: "mr-eaves-sans", sans-serif;
font-weight: 700;
font-style: italic; */

/* Mr Eaves San OT Heavy
font-family: "mr-eaves-sans", sans-serif;
font-weight: 800;
font-style: normal; */

/* Mr Eaves San OT Heavy Italic
font-family: "mr-eaves-sans", sans-serif;
font-weight: 800;
font-style: italic; */

/* Mrs Eaves OT Roman
@mixin font-mrs-eaves-ot-roman {
  font-family: "mrs-eaves", serif;
  font-weight: 400;
  font-style: normal;
} */

/* Mrs Eaves OT Italic
@mixin font-mrs-eaves-ot-roman-italic {
  font-family: "mrs-eaves", serif;
  font-weight: 400;
  font-style: italic;
} */

/* Mrs Eaves OT Bold
@mixin font-mrs-eaves-ot-bold {
  font-family: "mrs-eaves", serif;
  font-weight: 700;
  font-style: normal;
} */

/* Mrs Eaves OT Bold Italic
@mixin font-mrs-eaves-ot-bold-italic {
  font-family: "mrs-eaves", serif;
  font-weight: 700;
  font-style: italic;
} */

/* Mrs Eaves Roman Lining
font-family: "mrs-eaves-roman-lining", serif;
font-weight: 400;
font-style: normal; */

/* Mrs Eaves Roman Small Caps
font-family: "mrs-eaves-roman-small-caps", serif;
font-weight: 400;
font-style: normal; */

/* Mrs Eaves Roman All Small Caps
@mixin font-mrs-eaves-roman-all-small-caps {
  font-family: "mrs-eaves-roman-all-small-ca", serif;
  font-weight: 400;
  font-style: normal;
} */

/* Mrs Eaves Roman All Petite Caps
font-family: "mrs-eaves-roman-all-petite-c", serif;
font-weight: 400;
font-style: normal; */

/* Mrs Eaves Roman Petite Caps
font-family: "mrs-eaves-roman-petite-caps", serif;
font-weight: 400;
font-style: normal; */

/* Mrs Eaves XL Serif OT Reg */
@mixin font-mrs-eaves-xl-serif-ot-reg {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 400;
  font-style: normal;
}

/* Mrs Eaves XL Serif OT Reg Italic */
@mixin font-mrs-eaves-xl-serif-ot-reg-italic {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 400;
  font-style: italic;
}

/* Mrs Eaves XL Serif OT Bold */
@mixin font-mrs-eaves-xl-serif-ot-bold {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 700;
  font-style: normal;
}

/* Mrs Eaves XL Serif OT Bold Italic */
@mixin font-mrs-eaves-xl-serif-ot-bold-italic {
  font-family: "mrs-eaves-xl-serif", serif;
  font-weight: 700;
  font-style: italic;
}

/* Mrs Eaves XL Serif Nar OT Reg */
@mixin font-mrs-eaves-xl-serif-nar-ot-reg {
  font-family: "mrs-eaves-xl-serif-narrow", serif;
  font-weight: 400;
  font-style: normal;
}

/* Mrs Eaves XL Serif Nar OT Reg Italic */
@mixin font-mrs-eaves-xl-serif-nar-ot-reg-italic {
  font-family: "mrs-eaves-xl-serif-narrow", serif;
  font-weight: 400;
  font-style: italic;
}

/* Mrs Eaves XL Serif Nar OT Bold */
@mixin font-mrs-eaves-xl-serif-nar-ot-bold {
  font-family: "mrs-eaves-xl-serif-narrow", serif;
  font-weight: 700;
  font-style: normal;
}

/* Mrs Eaves XL Serif Nar OT Bold Italic */
@mixin font-mrs-eaves-xl-serif-nar-ot-bold-italic {
  font-family: "mrs-eaves-xl-serif-narrow", serif;
  font-weight: 700;
  font-style: italic;
}
