$color-olivine: #485e48;
$color-olivine-dark: #333b33;
$color-olivine-dark-blend: #81847d; // Comes from a blend mode in Figma
$color-olive-dark-blend-2: #c8cfc8; // Comes from blend mode in Figma (GiftCard amount titles)
$color-brick: #c7563f;
$color-brick-dark: #a04432; // TODO: made this up
$color-sage: #81ae9a;
$color-sage-dark: #568a73;
$color-sage-light: #cedccf;
$color-taupe: #ede6d8;
$color-taupe-blend: #a3a296;
$color-taupe-light: #f5f2ec;
$color-lavender: #92689e;
$color-lavender-dark: #7d488c; // TODO: made this up
$color-white-almost: #f8f7f5;
$color-white-almost-again: #f8f7f5; // TODO: input backgrounds?
$color-white-blend: #edefed; // Comes from blend mode in Figma (GiftCard amount value dollar sign)

$color-grey50: #eceff1;
$color-grey100: #cfd8dc;
$color-grey200: #b0bec5;
$color-grey300: #90a4ae;
$color-grey400: #78909c;
$color-grey500: #607d8b;
$color-grey600: #546e7a;
$color-grey700: #455a64;
$color-grey800: #37474f;
$color-grey900: #263238;
