@import "../../styles/config";

.Logo {
  width: 100%;
  height: 100%;
  text-indent: -999px;
  overflow: hidden;
  background-image: url(../../images/logo.svg);
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
