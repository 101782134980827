@import "../../styles/config";

.Header {
  background-color: $color-olivine;
  padding: 24px 0;

  &__logo {
    margin: 0 auto;
    width: $Logo-header-square-width;
    height: $Logo-header-square-height;
  }
}
