@import "../../styles/config";

.GiftCard {
  padding: 16px;
  width: 330px;

  &__intro {
    @include font-mr-eaves-mod-ot-reg;
    @include toRem(font-size, 14);
    @include toRem(line-height, 20);
    text-align: center;
    color: $color-olivine;
    margin: 0 0 8px 0;

    &_name {
      color: $color-lavender;
    }
  }

  &__wrapper {
    background-color: $color-white-almost;
    border-radius: 8px;
    box-shadow: 1px 6px 24px rgba(51, 59, 51, 0.25);
  }

  &__image {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;

    > img {
      display: block;
      max-width: 100%;
    }
  }

  &__amount {
    position: relative;
    background-color: $color-olivine;
    text-align: center;
    padding: 8px;

    &_title {
      @include font-mr-eaves-mod-ot-reg;
      @include toRem(font-size, 18);
      @include toRem(line-height, 18);
      display: inline-block;
      color: $color-olive-dark-blend-2;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transform: translateY(-3px);
    }
    &_value {
      @include font-mrs-eaves-xl-serif-nar-ot-bold-italic;
      @include toRem(font-size, 32);
      @include toRem(line-height, 32);
      display: inline-block;
      color: white;
      letter-spacing: 0.05em;
      transform: translateY(1px);

      &_currency {
        @include toRem(font-size, 22);
        @include toRem(line-height, 22);
        display: inline-block;
        color: $color-white-blend;
        transform: translateY(-3px);
      }
    }
  }

  &__note {
    padding: 16px 8px;
    text-align: center;

    &_receiver,
    &_giver {
      @include font-mrs-eaves-xl-serif-nar-ot-bold-italic;
      @include toRem(font-size, 24);
      @include toRem(line-height, 28);
    }

    &_receiver {
      margin: 0 0 8px 0;
    }
    &_copy {
      @include font-mrs-eaves-xl-serif-ot-reg-italic;
      @include toRem(font-size, 18);
      @include toRem(line-height, 24);
      margin: 0 0 8px 0;
    }
    &_giver {
      margin: 0;
    }
  }

  &._noNote {
    .GiftCard__amount {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &._noImg {
    .GiftCard__amount {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
