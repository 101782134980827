@import "../../styles/config";

.Gifts {
  padding: 24px;

  &__title {
    @include toRem(font-size, 32);
    @include toRem(line-height, 38);
    color: $color-olivine;
    padding: 0 0 24px 0;
    margin: 0 0 24px 0;
    border-bottom: 1px solid $color-sage;
  }
  &__gift {
    padding: 0 0 24px 0;
    margin: 0 0 24px 0;
    border-bottom: 1px solid $color-sage;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &._inverse {
    background-color: $color-olivine-dark;

    .Gifts__title {
      color: $color-taupe;
      border-bottom-color: $color-taupe-blend;
    }

    .Gifts__gift {
      border-bottom-color: $color-taupe-blend;
    }
  }
}
