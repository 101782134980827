@import "../../styles/config";

.Button {
  @include font-mrs-eaves-xl-serif-ot-reg;
  @include toRem(font-size, 18);
  @include toRem(line-height, 18);
  margin: 0;
  padding: 16px 24px;
  border: 1px solid transparent;
  color: white;
  background-color: $color-brick;
  text-align: center;
  // min-width: 240px;

  &:hover {
    background-color: $color-brick-dark;
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $color-brick;
    }
  }

  &._secondary {
    background-color: transparent;
    border: 1px solid $color-lavender;
    color: $color-lavender;

    &:hover {
      background-color: transparent;
      border: 1px solid $color-lavender-dark;
      color: $color-lavender-dark;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        border-color: $color-lavender;
        color: $color-lavender;
      }
    }
  }

  &._tertiary {
    background-color: transparent;
    border: 1px solid $color-sage;
    color: $color-sage;

    &:hover {
      background-color: transparent;
      border: 1px solid $color-sage-dark;
      color: $color-sage-dark;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        border-color: $color-sage;
        color: $color-sage;
      }
    }
  }
}
