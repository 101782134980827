@import "../../styles/config";

.Label {
  @include font-mr-eaves-mod-ot-bold;
  @include toRem(font-size, 16);
  @include toRem(line-height, 22);
  display: block;
  margin: 0 0 8px 0;

  &._hasHtmlFor {
    cursor: pointer;
  }
}
