$Logo-header-square-width: 85px;
$Logo-header-square-height: 51px;

$Logo-footer-square-width: 50px;
$Logo-footer-square-height: 30px;

$Gift-width: 320px;
$Gift-height: 200px;
$Gift-radius: 60px;

$App-boundary-width: 960px;

$Breakpoint-tablet: $App-boundary-width;
